<template>
  <div class="modal-md rounded-lg bg-white p-4 md:p-6">
    <div class="my-8 mt-4 text-center font-merri text-xl font-semibold">
      {{
        subscription.isOnTrial ? 'Extend your trial' : 'Time for a cat nap...'
      }}
    </div>
    <div class="space-y-4">
      <div class="px-2">
        <p v-if="!subscription.isOnTrial">
          Already got enough food or want to take a bit of a break, that's cool
          with us. Let us know when you want to pause until...
        </p>
        <p v-else>
          Need a bit more time to try all our flavours? No worries.. that's cool
          with us. Let us know how long you want to extend your trial until...
        </p>
      </div>
      <div
        v-if="hasError"
        class="error-msg mb-4 rounded border-2 border-red-400 bg-red-100 p-4 py-3 text-red-400"
      >
        Oh no... there was an error. {{ errorMessage }}
      </div>
      <div
        v-if="pauseAllowance > 0 || subscription.isOnTrial"
        class="space-y-4"
      >
        <div class="relative flex w-full flex-1 items-center sm:w-auto">
          <div
            class="pointer-events-none absolute left-0 p-3 pr-4 pt-3 text-lg text-gray-500"
          >
            <font-awesome-icon
              class="text-2xl"
              :icon="['fal', 'calendar-day']"
            />
          </div>
          <div
            class="pointer-events-none absolute right-0 p-3 pr-4 pt-3 text-lg text-gray-700"
          >
            <font-awesome-icon :icon="['far', 'chevron-down']" />
          </div>

          <select v-model="pauseLength" class="pl-12">
            <option key="choose-date" :value="null">Choose a date...</option>
            <option v-for="dt in dateRange" :key="dt.days" :value="dt.days">
              {{ dt.label }}
            </option>
          </select>
        </div>

        <div class="">
          <form @submit.prevent="submitPause" novalidate="true">
            <loading-button
              :is-loading="isLoading"
              :is-enabled="formValid"
              :is-full="true"
              class="mt-4"
              label="Confirm"
            />
          </form>
        </div>
      </div>

      <div v-else class="mb-4 flex items-start rounded bg-orange-100 p-3 px-4">
        <div class="pr-4">
          <font-awesome-icon
            class="mt-1 text-xl text-orange-400"
            :icon="['far', 'circle-exclamation']"
          />
        </div>
        <div>
          Unfortunately you can only pause up to a total of {{ maxDays }} days
          between boxes. Need to pause for longer? get in touch at...
          <div class="mt-4">
            <a href="mailto:team@thecoolcatclub.com">team@thecoolcatclub.com</a>
          </div>
        </div>
      </div>
      <div class="mt-6 text-center text-gray-600">
        <a @click="cancelModal">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
import { differenceInDays, addDays, format, fromUnixTime } from 'date-fns';
import LoadingButton from '@/components/LoadingButton';
import { isNull } from 'lodash/fp';
import { mapGetters } from 'vuex';
import http from '@/http';
export default {
  props: ['data', 'redirect'],
  components: {
    LoadingButton
  },

  computed: {
    formValid: function () {
      if (
        (this.pauseLength === '0' && !this.customDays) ||
        this.customError ||
        this.hitLimit ||
        isNull(this.pauseLength)
      ) {
        return false;
      }

      return true;
    },
    isPaused() {
      return this.subscription.ispaused;
    },

    customError() {
      return (
        Number(this.pauseLength) === 0 && Number(this.customDays) > this.maxDays
      );
    },
    daysBetween() {
      return (
        differenceInDays(
          new Date(this.subscription.nextbilldate),
          new Date(this.subscription.lastpaymentdate)
        ) + 1
      );
    },
    pauseAllowance() {
      return this.subscription?.pauseDaysLeft;
    },

    dateRange() {
      const dates = [];
      const date = new Date(this.subscription.nextbilldatefull);

      const days = this.isPaused
        ? this.pauseAllowance
        : this.subscription.isOnTrial
          ? 15
          : this.pauseAllowance;
      for (let index = 0; index < days + 1; index++) {
        if (index > 0) {
          dates.push({
            label: `${format(addDays(date, index), 'do MMMM, yyyy')}`,
            days: index
          });
        }
      }

      return dates;
    },
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    }),
    getNewPaymentDate() {
      if (this.pauseLength) {
        const date = fromUnixTime(this.subscription.nextbilldate / 1000);
        const days =
          Number(this.pauseLength) === 0 ? this.customDays : this.pauseLength;
        return format(addDays(date, days), 'do MMMM, yyyy');
      }
      return false;
    }
  },
  data() {
    return {
      password: '',
      isLoading: false,
      hasError: false,
      errorMessage: '',
      pauseLength: null,
      customDays: null,
      maxDays: 120,
      messageLimit: 500
    };
  },
  methods: {
    submitPause: function () {
      this.isLoading = true;
      this.hasError = false;
      http
        .put(
          `${
            this.subscription.isOnTrial
              ? '/subscripton/extendtrial'
              : '/subscripton/pause'
          }`,
          {
            days:
              Number(this.pauseLength) === 0
                ? this.customDays
                : this.pauseLength
          }
        )
        .then((response) => {
          this.isLoading = false;

          this.$store.dispatch('sendMpEvent', {
            event: 'Pause Subscription - Success',
            properties: {
              location: this.$route.name,

              type: 'EVT',
              days:
                Number(this.pauseLength) === 0
                  ? this.customDays
                  : this.pauseLength,
              unpause: this.getNewPaymentDate,
              isExtend: this.subscription.isOnTrial ? true : false
            }
          });
          let data = response;
          data.forward = true;
          // this.$store.commit('update_subscription', data);
          this.$store.dispatch('getSubscription');
          this.$store.dispatch('getUser');

          this.closeModal(this.redirect);
        })
        .catch((error) => {
          this.isLoading = false;
          this.hasError = true;
          this.errorMessage = error.response.data.error.message;

          this.$store.dispatch('sendMpEvent', {
            event: 'Pause Subscription - Error',
            properties: {
              location: this.$route.name,

              type: 'EVT',
              error: error.response.data.error.message
                ? error.response.data.error.message
                : 'Unknown Error'
            }
          });
          throw Error(error.response.data.error.message);
        });
    },
    closeModal: function (redirect) {
      this.$parent.$emit('make-close', redirect);
    },
    cancelModal() {
      this.closeModal();
    }
  },
  mounted() {
    this.$store.dispatch('sendMpEvent', {
      event: 'Pause Subscription View',
      properties: {
        location: this.$route.name,

        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
